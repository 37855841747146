import { createRoot } from 'react-dom/client';
import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ErrorFallback from './components/error-fallback';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { ClearErrors } from './actions/auth';

import App from './routes';

import Initializers from './initializers';
import InitializerUser from './initializers/user';

import 'sanitize.css/sanitize.css';
import './index.css';

import { store, persistedStore } from './wiring/store';

const onBeforeLift = () => {
    // Run initializers... anything that will need to use or subscribe to the store
    Initializers(store);

    // clear login/logout errors that may be in local storage
    store.dispatch(ClearErrors());

    if (store.getState().auth.isAuthenticated) {
        InitializerUser(store);
    }
};

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistedStore}
            onBeforeLift={onBeforeLift}
        >
            <BrowserRouter>
                <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
                    <App />
                </Sentry.ErrorBoundary>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
